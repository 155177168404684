<template>
  <CFooter>
    <div class="ms-auto">
      <span class="me-1 app-version" target="_blank">
        UMWA TOOL V{{ appVersion }} (API V{{ appApiVersion }})
      </span>
    </div>
  </CFooter>
</template>

<script>
import p from '../../package'
export default {
  name: 'AppFooter',
  data() {
    return {
      appVersion: p.version,
      appApiVersion: '1.4.6',
    }
  },
}
</script>
