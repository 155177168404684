export const logo = [
  '642 174',
  `
  <title>umwa tool</title>
  <g transform="matrix(1,0,0,1,0,-126)">
        <g>
            <g>
                <path d="M99.835,36.058L60.835,13.541C57.127,11.4 52.543,11.4 48.835,13.541L9.835,36.058C6.132,38.204 3.842,42.17 3.835,46.45L3.835,91.483C3.842,95.763 6.132,99.73 9.835,101.876L48.835,124.392C52.543,126.533 57.127,126.533 60.835,124.392L99.835,101.876C103.538,99.73 105.828,95.763 105.835,91.483L105.835,46.45C105.828,42.17 103.538,38.204 99.835,36.058ZM97.835,91.483C97.835,92.911 97.071,94.234 95.835,94.947L56.835,117.464C55.599,118.178 54.071,118.178 52.835,117.464L13.835,94.947C12.599,94.234 11.835,92.911 11.835,91.483L11.835,46.45C11.835,45.023 12.599,43.699 13.835,42.986L52.835,20.469C54.071,19.756 55.599,19.756 56.835,20.469L95.835,42.986C97.071,43.699 97.835,45.023 97.835,46.45L97.835,91.483Z" style="fill:rgb(60,75,100);"/>
            </g>
        </g>
        <g transform="matrix(1.29292,0,0,1.29292,18.3182,-18.3467)">
            <g transform="matrix(0.443182,0,0,0.483193,110.727,77.521)">
                <path d="M68.064,252.425C72.64,252.425 76.732,251.725 80.34,250.326C83.948,248.927 86.999,246.963 89.492,244.434C91.985,241.905 93.892,238.838 95.212,235.233C96.532,231.628 97.192,227.592 97.192,223.126L97.192,153.229L120.864,153.229L120.864,223.126C120.864,230.067 119.647,236.484 117.212,242.376C114.777,248.268 111.272,253.353 106.696,257.631C102.12,261.909 96.576,265.245 90.064,267.639C83.552,270.034 76.219,271.231 68.064,271.231C59.909,271.231 52.576,270.034 46.064,267.639C39.552,265.245 34.023,261.909 29.476,257.631C24.929,253.353 21.439,248.268 19.004,242.376C16.569,236.484 15.352,230.067 15.352,223.126L15.352,153.229L39.024,153.229L39.024,223.045C39.024,227.511 39.684,231.547 41.004,235.152C42.324,238.757 44.216,241.838 46.68,244.394C49.144,246.95 52.18,248.927 55.788,250.326C59.396,251.725 63.488,252.425 68.064,252.425Z" style="fill:rgb(60,75,100);fill-rule:nonzero;"/>
                <path d="M208.864,221.512C209.861,223.234 210.785,225.023 211.636,226.879C212.487,228.736 213.323,230.605 214.144,232.489C214.965,230.552 215.816,228.641 216.696,226.758C217.576,224.875 218.515,223.072 219.512,221.35L257.176,156.699C257.645,155.892 258.129,155.246 258.628,154.762C259.127,154.278 259.684,153.928 260.3,153.713C260.916,153.498 261.605,153.363 262.368,153.309C263.131,153.255 264.04,153.229 265.096,153.229L282.96,153.229L282.96,269.94L262.104,269.94L262.104,194.554C262.104,193.155 262.148,191.621 262.236,189.953C262.324,188.285 262.456,186.59 262.632,184.868L224.176,251.053C223.296,252.559 222.152,253.73 220.744,254.564C219.336,255.398 217.693,255.815 215.816,255.815L212.56,255.815C210.683,255.815 209.04,255.398 207.632,254.564C206.224,253.73 205.08,252.559 204.2,251.053L165.216,184.626C165.451,186.402 165.612,188.137 165.7,189.832C165.788,191.527 165.832,193.101 165.832,194.554L165.832,269.94L144.976,269.94L144.976,153.229L162.84,153.229C163.896,153.229 164.805,153.255 165.568,153.309C166.331,153.363 167.02,153.498 167.636,153.713C168.252,153.928 168.824,154.278 169.352,154.762C169.88,155.246 170.379,155.892 170.848,156.699L208.864,221.512Z" style="fill:rgb(60,75,100);fill-rule:nonzero;"/>
                <path d="M296.864,153.229L316.752,153.229C318.805,153.229 320.521,153.672 321.9,154.56C323.279,155.448 324.203,156.645 324.672,158.152L346.32,227.969C346.848,229.691 347.332,231.561 347.772,233.578C348.212,235.596 348.637,237.735 349.048,239.995C349.517,237.735 350.031,235.596 350.588,233.578C351.145,231.561 351.747,229.691 352.392,227.969L377.384,158.152C377.853,156.914 378.777,155.784 380.156,154.762C381.535,153.74 383.221,153.229 385.216,153.229L392.168,153.229C394.221,153.229 395.923,153.672 397.272,154.56C398.621,155.448 399.56,156.645 400.088,158.152L424.904,227.969C426.195,231.413 427.309,235.233 428.248,239.43C428.659,237.332 429.084,235.314 429.524,233.377C429.964,231.439 430.419,229.637 430.888,227.969L452.536,158.152C452.947,156.807 453.856,155.65 455.264,154.681C456.672,153.713 458.373,153.229 460.368,153.229L478.936,153.229L439.424,269.94L418.04,269.94L390.232,190.195C389.88,189.173 389.513,188.07 389.132,186.886C388.751,185.702 388.384,184.438 388.032,183.092C387.68,184.438 387.313,185.702 386.932,186.886C386.551,188.07 386.184,189.173 385.832,190.195L357.76,269.94L336.376,269.94L296.864,153.229Z" style="fill:rgb(60,75,100);fill-rule:nonzero;"/>
                <path d="M594.304,269.94L576,269.94C573.947,269.94 572.26,269.469 570.94,268.527C569.62,267.585 568.667,266.415 568.08,265.016L558.576,241.206L505.864,241.206L496.36,265.016C495.891,266.254 494.967,267.384 493.588,268.406C492.209,269.428 490.523,269.94 488.528,269.94L470.136,269.94L520.12,153.229L544.32,153.229L594.304,269.94ZM511.936,225.87L552.504,225.87L537.016,187.047C536.312,185.325 535.535,183.294 534.684,180.953C533.833,178.613 532.997,176.07 532.176,173.326C531.355,176.07 530.548,178.626 529.756,180.994C528.964,183.361 528.187,185.433 527.424,187.209L511.936,225.87Z" style="fill:rgb(60,75,100);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,0.843231,84,18.8325)">
                <path d="M307.68,157.949L307.68,160.012L297.74,160.012L297.74,191.51L295.7,191.51L295.7,160.012L285.7,160.012L285.7,157.949L307.68,157.949Z" style="fill:rgb(27,189,147);fill-rule:nonzero;"/>
                <path d="M335.42,174.718C335.42,177.311 335.093,179.663 334.44,181.774C333.787,183.885 332.867,185.687 331.68,187.182C330.493,188.676 329.073,189.83 327.42,190.644C325.767,191.459 323.933,191.866 321.92,191.866C319.933,191.866 318.113,191.459 316.46,190.644C314.807,189.83 313.387,188.676 312.2,187.182C311.013,185.687 310.09,183.885 309.43,181.774C308.77,179.663 308.44,177.311 308.44,174.718C308.44,172.14 308.77,169.796 309.43,167.685C310.09,165.574 311.013,163.772 312.2,162.277C313.387,160.783 314.807,159.625 316.46,158.803C318.113,157.98 319.933,157.569 321.92,157.569C323.933,157.569 325.767,157.976 327.42,158.791C329.073,159.605 330.493,160.763 331.68,162.265C332.867,163.768 333.787,165.574 334.44,167.685C335.093,169.796 335.42,172.14 335.42,174.718ZM333.3,174.718C333.3,172.377 333.027,170.274 332.48,168.409C331.933,166.543 331.163,164.961 330.17,163.665C329.177,162.368 327.98,161.376 326.58,160.688C325.18,160 323.627,159.656 321.92,159.656C320.24,159.656 318.703,160 317.31,160.688C315.917,161.376 314.717,162.368 313.71,163.665C312.703,164.961 311.923,166.543 311.37,168.409C310.817,170.274 310.54,172.377 310.54,174.718C310.54,177.074 310.817,179.181 311.37,181.038C311.923,182.896 312.703,184.474 313.71,185.77C314.717,187.067 315.917,188.055 317.31,188.735C318.703,189.415 320.24,189.755 321.92,189.755C323.627,189.755 325.18,189.415 326.58,188.735C327.98,188.055 329.177,187.067 330.17,185.77C331.163,184.474 331.933,182.896 332.48,181.038C333.027,179.181 333.3,177.074 333.3,174.718Z" style="fill:rgb(27,189,147);fill-rule:nonzero;"/>
                <path d="M367.32,174.718C367.32,177.311 366.993,179.663 366.34,181.774C365.687,183.885 364.767,185.687 363.58,187.182C362.393,188.676 360.973,189.83 359.32,190.644C357.667,191.459 355.833,191.866 353.82,191.866C351.833,191.866 350.013,191.459 348.36,190.644C346.707,189.83 345.287,188.676 344.1,187.182C342.913,185.687 341.99,183.885 341.33,181.774C340.67,179.663 340.34,177.311 340.34,174.718C340.34,172.14 340.67,169.796 341.33,167.685C341.99,165.574 342.913,163.772 344.1,162.277C345.287,160.783 346.707,159.625 348.36,158.803C350.013,157.98 351.833,157.569 353.82,157.569C355.833,157.569 357.667,157.976 359.32,158.791C360.973,159.605 362.393,160.763 363.58,162.265C364.767,163.768 365.687,165.574 366.34,167.685C366.993,169.796 367.32,172.14 367.32,174.718ZM365.2,174.718C365.2,172.377 364.927,170.274 364.38,168.409C363.833,166.543 363.063,164.961 362.07,163.665C361.077,162.368 359.88,161.376 358.48,160.688C357.08,160 355.527,159.656 353.82,159.656C352.14,159.656 350.603,160 349.21,160.688C347.817,161.376 346.617,162.368 345.61,163.665C344.603,164.961 343.823,166.543 343.27,168.409C342.717,170.274 342.44,172.377 342.44,174.718C342.44,177.074 342.717,179.181 343.27,181.038C343.823,182.896 344.603,184.474 345.61,185.77C346.617,187.067 347.817,188.055 349.21,188.735C350.603,189.415 352.14,189.755 353.82,189.755C355.527,189.755 357.08,189.415 358.48,188.735C359.88,188.055 361.077,187.067 362.07,185.77C363.063,184.474 363.833,182.896 364.38,181.038C364.927,179.181 365.2,177.074 365.2,174.718Z" style="fill:rgb(27,189,147);fill-rule:nonzero;"/>
                <path d="M376.02,189.447L389.44,189.447L389.44,191.51L373.98,191.51L373.98,157.949L376.02,157.949L376.02,189.447Z" style="fill:rgb(27,189,147);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1.31199,0,0,1.31199,-152.099,-199.98)">
                <path d="M158.197,247.195C155.517,246.935 152.818,246.935 150.138,247.195L148.81,253.658C146.241,254.051 143.723,254.725 141.302,255.669L136.921,250.737C134.47,251.851 132.132,253.201 129.941,254.766L132.023,261.027C129.995,262.652 128.152,264.495 126.527,266.523L120.266,264.441C118.701,266.632 117.351,268.97 116.237,271.421L121.169,275.802C120.225,278.223 119.551,280.741 119.158,283.31L112.695,284.638C112.435,287.318 112.435,290.017 112.695,292.697L119.158,294.025C119.551,296.594 120.225,299.112 121.169,301.533L116.237,305.914C117.351,308.365 118.701,310.703 120.266,312.894L126.527,310.812C128.152,312.84 129.995,314.683 132.023,316.308L129.941,322.569C132.132,324.134 134.47,325.484 136.921,326.598L141.302,321.666C143.723,322.61 146.241,323.284 148.81,323.677L150.138,330.14C152.818,330.4 155.517,330.4 158.197,330.14L159.525,323.677C162.094,323.284 164.612,322.61 167.033,321.666L171.414,326.598C173.865,325.484 176.203,324.134 178.394,322.569L176.312,316.308C178.34,314.683 180.183,312.84 181.808,310.812L188.069,312.894C189.634,310.703 190.984,308.365 192.098,305.914L187.166,301.533C188.11,299.112 188.784,296.594 189.177,294.025L195.64,292.697C195.9,290.017 195.9,287.318 195.64,284.638L189.177,283.31C188.784,280.741 188.11,278.223 187.166,275.802L192.098,271.421C190.984,268.97 189.634,266.632 188.069,264.441L181.808,266.523C180.183,264.495 178.34,262.652 176.312,261.027L178.394,254.766C176.203,253.201 173.865,251.851 171.414,250.737L167.033,255.669C164.612,254.725 162.094,254.051 159.525,253.658L158.197,247.195ZM154.168,280.334C158.767,280.334 162.501,284.068 162.501,288.667C162.501,293.267 158.767,297.001 154.168,297.001C149.568,297.001 145.834,293.267 145.834,288.667C145.834,284.068 149.568,280.334 154.168,280.334Z" style="fill:white;stroke:rgb(60,75,100);stroke-width:3.54px;"/>
            </g>
        </g>
    </g>
`,
]
