<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" :caret="false" @click="activeLink">
      <CIcon class="my-1 mx-2" icon="cil-user" size="lg" />
      <span class="username">
        {{ firstname }} {{ lastname }} ({{ userGroup }})
      </span>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader
        component="h6"
        class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10 py-2"
      >
        Account
      </CDropdownHeader>
      <CDropdownDivider />
      <CDropdownItem href="/#/backend/account/password">
        <CIcon icon="cil-key" /> Passwort ändern
      </CDropdownItem>
      <CDropdownItem href="/#/backend/logout">
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      itemsCount: 42,
      firstname: '',
      lastname: '',
      userGroup: '',
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  created() {
    if (this.loggedIn === true) {
      const user = JSON.parse(localStorage.getItem('user'))
      this.firstname = user.firstname
      this.lastname = user.lastname
      this.userGroup = user.userGroup
    }
  },
  methods: {
    activeLink(event) {
      event.preventDefault()
    },
  },
}
</script>
