export default [
  {
    path: '/backend/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/user/dashboard/Dashboard.vue'),
  },
  {
    path: '/backend/account/password',
    name: 'changePassword',
    label: 'Passwort ändern',
    component: () => import('@/views/user/account/Password.vue'),
  },
  {
    path: '/backend/processes',
    name: 'processesOverview',
    label: 'Übersicht',
    component: () => import('@/views/user/processes/Overview.vue'),
  },
  {
    path: '/backend/results/mainsurvey/analytics',
    name: 'Analytics',
    label: 'Hauptbogen',
    component: () => import('@/views/user/results/survey/main.vue'),
  },
  {
    path: '/backend/results/mainsurvey/comments',
    name: 'Comments',
    label: 'Anmerkungen',
    component: () => import('@/views/user/results/survey/comments.vue'),
  },
  {
    path: '/backend/processes/overview',
    name: 'Vorgänge',
    label: 'Vorgänge',
    component: () => import('@/views/user/processes/Overview.vue'),
  },
  {
    path: '/backend/waitlist/overview/:waitlistLocationId',
    name: 'Warteliste',
    label: 'Warteliste',
    component: () => import('@/views/user/waitlist/Overview.vue'),
  },
  {
    path: '/backend/waitlist/lastminute',
    name: 'Last-Minute',
    label: 'Last-Minute',
    component: () => import('@/views/user/waitlist/Lastminute.vue'),
  },
]
