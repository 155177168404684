export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/backend/dashboard',
    icon: 'cil-home',
  },
  {
    component: 'CNavGroup',
    name: 'Benutzer',
    to: '/buttons',
    icon: 'cil-people',
    items: [
      {
        component: 'CNavItem',
        name: 'Systembenutzer',
        to: '/backend/user',
        icon: 'cil-user',
      },
      {
        component: 'CNavItem',
        name: 'Kontakte',
        to: '/backend/contact',
        icon: 'cil-contact-mail',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Einrichtungen',
    to: '/buttons',
    icon: 'cil-spa',
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        to: '/backend/clinic',
        icon: 'cil-chart-table',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Vorgänge',
    to: '/backend/processes/overview',
    icon: 'cil-envelope-letter',
  },
  {
    component: 'CNavGroup',
    name: 'Auswertungen',
    icon: 'cil-chart-line',
    items: [
      {
        component: 'CNavItem',
        name: 'Hauptbogen',
        to: '/backend/results/mainsurvey/analytics',
        icon: 'cil-chart-table',
      },
      {
        component: 'CNavItem',
        name: 'Anmerkungen',
        to: '/backend/results/mainsurvey/comments',
        icon: 'cil-chart-table',
      },
    ],
  },
  {
    component: 'CNavTitle',
    name: 'Warteliste',
  },
  {
    component: 'CNavItem',
    name: 'Einrichtungen',
    to: '/backend/waitlist/overview',
    icon: 'cil-home',
    items: [
      {
        component: 'CNavItem',
        name: 'Borkum',
        to: '/backend/waitlist/overview/1',
        icon: 'cil-home',
      },
      {
        component: 'CNavItem',
        name: 'Buckow',
        to: '/backend/waitlist/overview/2',
        icon: 'cil-home',
      },
      {
        component: 'CNavItem',
        name: 'Schwabenland',
        to: '/backend/waitlist/overview/3',
        icon: 'cil-home',
      },
      {
        component: 'CNavItem',
        name: 'Feldberg',
        to: '/backend/waitlist/overview/4',
        icon: 'cil-home',
      },
      {
        component: 'CNavItem',
        name: 'Zorge',
        to: '/backend/waitlist/overview/5',
        icon: 'cil-home',
      },
      {
        component: 'CNavItem',
        name: 'Saarwald',
        to: '/backend/waitlist/overview/7',
        icon: 'cil-home',
      },
      {
        component: 'CNavItem',
        name: 'Selent',
        to: '/backend/waitlist/overview/10',
        icon: 'cil-home',
      },
      {
        component: 'CNavItem',
        name: 'Horumersiel',
        to: '/backend/waitlist/overview/13',
        icon: 'cil-home',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Last-Minute',
    to: '/backend/waitlist/lastminute',
    icon: 'cil-clock',
  },
]
