<template>
  <div v-if="loading">
    <div class="overlay"></div>
    <div class="spanner">
      <div class="lds-ripple">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div>Einen Augenblick bitte ...</div>
    </div>
  </div>
  <router-view />
</template>
<script>
import { watch } from 'vue'
//import { useStore } from 'vuex'
import store from '@/store'
//import { toast } from 'vue3-toastify'

export default {
  setup() {
    /*watch(store.state, () => {
      if (store.state.message !== '') {
        console.log(store.state.message)
        toast(store.state.message, {
          autoClose: 5000,
          type: 'success',
          theme: 'colored',
          position: 'top-center',
          closeOnClick: false,
          multiple: false,
        })
        //store.state.message = ''
      }
    })*/

    watch(store.state, () => {
      store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')
    })
    /*
    store.state.theme === 'dark'
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark-theme')

     */
  },
  data() {
    return {
      m: store.getters.message,
    }
  },
  computed: {
    loading: function () {
      return store.state.loader.loading
    },
  },
  methods: {},
}
</script>

<style lang="scss">
// Import MainController styles for this application
@import 'styles/style';
</style>
