import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
//import DocsCallout from '@/components/DocsCallout'
//import DocsExample from '@/components/DocsExample'
import { Modal } from 'usemodal-vue3'

//import '@coreui/coreui-pro/dist/css/coreui.min.css'

const app = createApp(App)

//app.use(Vuelidate)
app.use(store)
app.use(router)
app.use(CoreuiVue)
//app.component(ApiService)
app.use(Modal)
//app.use(Vuelidate)
app.provide('icons', icons)
app.component('CIcon', CIcon)
//app.component('DocsCallout', DocsCallout)
//app.component('DocsExample', DocsExample)

app.mount('#app')
