<template>
  <CHeader position="sticky">
    <CContainer fluid>
      <CHeaderToggler class="ps-0" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="xxl" />
      </CHeaderToggler>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
//import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
//import AppHeaderDropdownTasks from './AppHeaderDropdownTasks'
import { logo } from '@/assets/brand/logo'

export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
    //AppHeaderDropdownNotif,
  },
  setup() {
    return {
      logo,
    }
  },
  watch: {
    columnFilter() {
      this.getUsers()
    },
    columnSorter: {
      handler() {
        this.getUsers()
      },
      deep: true,
    },
    message: {
      handler() {
        alert('TEST')
      },
    },
  },
}
</script>
