export default [
  {
    path: '/backend/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/admin/dashboard/Dashboard.vue'),
  },
  {
    path: '/backend/account/password',
    name: 'changePassword',
    label: 'Passwort ändern',
    component: () => import('@/views/user/account/Password.vue'),
  },
  {
    path: '/backend/clinic',
    name: 'clinicOverview',
    label: 'Übersicht',
    component: () => import('@/views/admin/clinic/Overview.vue'),
  },
  {
    path: '/backend/clinic/edit/:clinicId?',
    name: 'clinicEdit',
    label: 'Edit',
    component: () => import('@/views/admin/clinic/AddOrEdit.vue'),
  },
  {
    path: '/backend/clinic/add/',
    name: 'clinicAdd',
    label: 'Neu',
    component: () => import('@/views/admin/clinic/AddOrEdit.vue'),
  },
  {
    path: '/backend/user',
    name: 'userOverview',
    label: 'Übersicht',
    component: () => import('@/views/admin/user/Overview.vue'),
  },
  {
    path: '/backend/user/edit/:userId?',
    name: 'userEdit',
    label: 'Bearbeiten',
    component: () => import('@/views/admin/user/AddOrEdit.vue'),
  },
  {
    path: '/backend/user/add/',
    name: 'userAdd',
    label: 'Neu',
    component: () => import('@/views/admin/user/AddOrEdit.vue'),
  },
  {
    path: '/backend/contact',
    name: 'contactOverview',
    label: 'Übersicht',
    component: () => import('@/views/admin/contact/Overview.vue'),
  },
  {
    path: '/backend/contact/edit/:contactId?',
    name: 'contactEdit',
    label: 'Bearbeiten',
    component: () => import('@/views/admin/contact/AddOrEdit.vue'),
  },
  {
    path: '/backend/contact/add',
    name: 'contactAdd',
    label: 'Neu',
    component: () => import('@/views/admin/contact/AddOrEdit.vue'),
  },
  {
    path: '/backend/mailingList',
    name: 'listOverview',
    label: 'Listen',
    component: () => import('@/views/admin/mailingList/Overview.vue'),
  },
  {
    path: '/backend/mailingList/edit/:listId?',
    name: 'listEdit',
    label: 'Bearbeiten',
    component: () => import('@/views/admin/mailingList/Edit.vue'),
  },
  {
    path: '/backend/list/add',
    name: 'listAdd',
    label: 'Neu',
    component: () => import('@/views/admin/user/AddOrEdit.vue'),
  },
  {
    path: '/backend/umfrage/forms',
    name: 'Formulare',
    component: () => import('@/views/umfrage/Forms.vue'),
  },
  {
    path: '/backend/results/mainsurvey/analytics',
    name: 'Analytics',
    label: 'Hauptbogen',
    component: () => import('@/views/user/results/survey/main.vue'),
  },
  {
    path: '/backend/results/mainsurvey/comments',
    name: 'Comments',
    label: 'Anmerkungen',
    component: () => import('@/views/user/results/survey/comments.vue'),
  },
  {
    path: '/backend/processes/overview',
    name: 'Vorgänge',
    label: 'Vorgänge',
    component: () => import('@/views/user/processes/Overview.vue'),
  },
  {
    path: '/backend/waitlist/overview/:waitlistLocationId',
    name: 'Warteliste',
    label: 'Warteliste',
    component: () => import('@/views/user/waitlist/Overview.vue'),
  },
  {
    path: '/backend/waitlist/lastminute',
    name: 'Last-Minute',
    label: 'Last-Minute',
    component: () => import('@/views/user/waitlist/Lastminute.vue'),
  },
]
