import { createRouter, createWebHashHistory } from 'vue-router'
import axios from 'axios'

import DefaultLayout from '@/layouts/DefaultLayout'

import routeSuperAdmin from '@/router/SuperAdministrator/route.js'
import routeAdmin from '@/router/Administrator/route.js'
import routeFacility from '@/router/Facility/route.js'

import 'vue3-toastify/dist/index.css'
//import { watch } from 'vue'
import store from '@/store'
import { toast } from 'vue3-toastify'

let user = JSON.parse(localStorage.getItem('user'))

let customRoute = ''

if (user) {
  if (user.role == '1') {
    customRoute = routeSuperAdmin
  } else if (user.role == '2') {
    customRoute = routeAdmin
  } else {
    customRoute = routeFacility
  }
}

let routes = [
  {
    path: '/:processIdent',
    component: () => import('@/views/frontend/Survey.vue'),
    children: [
      {
        path: '/survey/:processIdent',
        name: 'Survey',
      },
    ],
  },
  {
    path: '/backend',
    component: DefaultLayout,
    redirect: '/backend/dashboard',
    children: customRoute,
  },
  {
    path: '/backend/password',
    name: 'Passwort vergessen',
    component: () => import('@/views/ForgotPassword.vue'),
  },
  {
    path: '/backend/setPassword/:setPasswordToken',
    name: 'Passwort',
    component: () => import('@/views/SetPassword.vue'),
  },
  {
    path: '/backend/login',
    name: 'Login',
    component: () => import('@/views/Login'),
  },
  {
    path: '/backend/logout',
    name: 'Logout',
    component: () => import('@/views/Logout'),
  },
  {
    path: '/page503',
    component: () => import('@/views/Page503.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/page404',
    children: [
      {
        path: '/page404',
        component: () => import('@/views/Page404.vue'),
      },
    ],
  },
]

let router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  const publicPages = [
    '/backend/login',
    '/backend/logout',
    '/page404',
    '/page503',
  ]
  let authRequired = !publicPages.includes(to.path)

  if (to.matched[0]) {
    if (to.matched[0].path == '/backend/password') {
      authRequired = false
      document.title = 'Passwort vergessen'
    }
    if (to.matched[0].path == '/backend/setPassword/:setPasswordToken') {
      authRequired = false
      document.title = 'Passwort setzen'
    }
    if (to.matched[0].path == '/:processIdent') {
      authRequired = false
      document.title = 'Umfrage'
    }
  }
  let API_URL = 'https://' + window.location.host + ''

  if (window.location.host === '192.168.2.44:8443') {
    API_URL = 'https://dev.umfrage2023.kur.org'
  }

  let user = JSON.parse(localStorage.getItem('user'))

  if (user) {
    axios.defaults.headers['Authorization'] = 'Bearer ' + user.accessToken
  }
  //let canUserAccess = function (to) {
  //return true
  //}
  if (authRequired && user) {
    //const canAccess = await canUserAccess(to)
    //console.log(canAccess)
    const params = { refresh: true }
    axios.post(API_URL + '/api/auth/checkLogin', params).then((response) => {
      if (response) {
        if (response.data.status == 'error') {
          user = null
          router.push('/backend/logout')
        }
      } else {
        user = null
        router.push('/backend/logout')
      }
    })
  }
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !user) {
    next('/backend/login')
  } else {
    next()
  }
})

router.afterEach(() => {
  if (store.state.message !== '') {
    toast(store.state.message, {
      autoClose: 5000,
      type: store.state.messageTyp,
      theme: 'colored',
      position: 'top-center',
      closeOnClick: false,
      //multiple: false,
    })
    store.state.message = ''
  }
})

export default router
