import axios from 'axios'
import jwtDecode from 'jwt-decode'

let API_URL = 'https://' + window.location.host + '/api/auth/login'

if (window.location.host === '192.168.2.44:8443') {
  API_URL = 'https://dev.umfrage2023.kur.org/api/auth/login'
}
class AuthService {
  getExpirationTime() {
    // const secondsAhead = EXPIRATION_SECS_AHEAD
    const nowSeconds = Math.ceil(Date.now() / 1000)
    return nowSeconds // + secondsAhead
  }
  getLivingData(token) {
    if (token) {
      //console.log('token =', token)
      const payload = jwtDecode(token)
      console.log(
        'payload expiration =',
        payload && new Date(payload.exp * 1000),
      )
      if (payload.exp > this.getExpirationTime()) {
        console.log('token is valid and not expired.')
        return {
          token,
          payload,
        }
      }
      console.log('token is expired.')
    }
    return null
  }
  login(user) {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        //console.log(error.message)
        return Promise.reject(error)
      },
    )
    return axios
      .post(API_URL, {
        username: user.username,
        password: user.password,
      })
      .then(
        (response) => {
          if (response.data.status == 'success') {
            if (response.data.data.accessToken) {
              let livingData = this.getLivingData(
                response.data.data.accessToken,
              )
              let userData = {
                username: response.data.data.username,
                firstname: response.data.data.firstname,
                lastname: response.data.data.lastname,
                userGroup: response.data.data.userGroup,
                role: livingData.payload.roleId,
                accessToken: livingData.token,
              }
              //console.log(userData)
              localStorage.setItem('user', JSON.stringify(userData))
            }
            //console.log(response.data)
            return response.data
          } else {
            return response.data
          }
        },
        (error) => {
          console.log(error)
        },
      )
  }

  logout() {
    localStorage.removeItem('user')
    document.location.href = '#/backend/'
  }
}

export default new AuthService()
