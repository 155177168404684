export const logoNegative = [
  '624 153',
  `
  <title>coreui vue logo</title>
  <g transform="matrix(1.29292,0,0,1.29292,8.31816,-153.347)">
      <g>
          <g transform="matrix(0.443182,0,0,0.483193,110.727,77.521)">
              <path d="M68.064,252.425C72.64,252.425 76.732,251.725 80.34,250.326C83.948,248.927 86.999,246.963 89.492,244.434C91.985,241.905 93.892,238.838 95.212,235.233C96.532,231.628 97.192,227.592 97.192,223.126L97.192,153.229L120.864,153.229L120.864,223.126C120.864,230.067 119.647,236.484 117.212,242.376C114.777,248.268 111.272,253.353 106.696,257.631C102.12,261.909 96.576,265.245 90.064,267.639C83.552,270.034 76.219,271.231 68.064,271.231C59.909,271.231 52.576,270.034 46.064,267.639C39.552,265.245 34.023,261.909 29.476,257.631C24.929,253.353 21.439,248.268 19.004,242.376C16.569,236.484 15.352,230.067 15.352,223.126L15.352,153.229L39.024,153.229L39.024,223.045C39.024,227.511 39.684,231.547 41.004,235.152C42.324,238.757 44.216,241.838 46.68,244.394C49.144,246.95 52.18,248.927 55.788,250.326C59.396,251.725 63.488,252.425 68.064,252.425Z" style="fill:white;fill-rule:nonzero;stroke:rgb(60,75,100);stroke-width:1.67px;"/>
              <path d="M208.864,221.512C209.861,223.234 210.785,225.023 211.636,226.879C212.487,228.736 213.323,230.605 214.144,232.489C214.965,230.552 215.816,228.641 216.696,226.758C217.576,224.875 218.515,223.072 219.512,221.35L257.176,156.699C257.645,155.892 258.129,155.246 258.628,154.762C259.127,154.278 259.684,153.928 260.3,153.713C260.916,153.498 261.605,153.363 262.368,153.309C263.131,153.255 264.04,153.229 265.096,153.229L282.96,153.229L282.96,269.94L262.104,269.94L262.104,194.554C262.104,193.155 262.148,191.621 262.236,189.953C262.324,188.285 262.456,186.59 262.632,184.868L224.176,251.053C223.296,252.559 222.152,253.73 220.744,254.564C219.336,255.398 217.693,255.815 215.816,255.815L212.56,255.815C210.683,255.815 209.04,255.398 207.632,254.564C206.224,253.73 205.08,252.559 204.2,251.053L165.216,184.626C165.451,186.402 165.612,188.137 165.7,189.832C165.788,191.527 165.832,193.101 165.832,194.554L165.832,269.94L144.976,269.94L144.976,153.229L162.84,153.229C163.896,153.229 164.805,153.255 165.568,153.309C166.331,153.363 167.02,153.498 167.636,153.713C168.252,153.928 168.824,154.278 169.352,154.762C169.88,155.246 170.379,155.892 170.848,156.699L208.864,221.512Z" style="fill:white;fill-rule:nonzero;stroke:rgb(60,75,100);stroke-width:1.67px;"/>
              <path d="M296.864,153.229L316.752,153.229C318.805,153.229 320.521,153.672 321.9,154.56C323.279,155.448 324.203,156.645 324.672,158.152L346.32,227.969C346.848,229.691 347.332,231.561 347.772,233.578C348.212,235.596 348.637,237.735 349.048,239.995C349.517,237.735 350.031,235.596 350.588,233.578C351.145,231.561 351.747,229.691 352.392,227.969L377.384,158.152C377.853,156.914 378.777,155.784 380.156,154.762C381.535,153.74 383.221,153.229 385.216,153.229L392.168,153.229C394.221,153.229 395.923,153.672 397.272,154.56C398.621,155.448 399.56,156.645 400.088,158.152L424.904,227.969C426.195,231.413 427.309,235.233 428.248,239.43C428.659,237.332 429.084,235.314 429.524,233.377C429.964,231.439 430.419,229.637 430.888,227.969L452.536,158.152C452.947,156.807 453.856,155.65 455.264,154.681C456.672,153.713 458.373,153.229 460.368,153.229L478.936,153.229L439.424,269.94L418.04,269.94L390.232,190.195C389.88,189.173 389.513,188.07 389.132,186.886C388.751,185.702 388.384,184.438 388.032,183.092C387.68,184.438 387.313,185.702 386.932,186.886C386.551,188.07 386.184,189.173 385.832,190.195L357.76,269.94L336.376,269.94L296.864,153.229Z" style="fill:white;fill-rule:nonzero;stroke:rgb(60,75,100);stroke-width:1.67px;"/>
              <path d="M594.304,269.94L576,269.94C573.947,269.94 572.26,269.469 570.94,268.527C569.62,267.585 568.667,266.415 568.08,265.016L558.576,241.206L505.864,241.206L496.36,265.016C495.891,266.254 494.967,267.384 493.588,268.406C492.209,269.428 490.523,269.94 488.528,269.94L470.136,269.94L520.12,153.229L544.32,153.229L594.304,269.94ZM511.936,225.87L552.504,225.87L537.016,187.047C536.312,185.325 535.535,183.294 534.684,180.953C533.833,178.613 532.997,176.07 532.176,173.326C531.355,176.07 530.548,178.626 529.756,180.994C528.964,183.361 528.187,185.433 527.424,187.209L511.936,225.87Z" style="fill:white;fill-rule:nonzero;stroke:rgb(60,75,100);stroke-width:1.67px;"/>
          </g>
          <g transform="matrix(0.773441,0,0,0.773441,220.185,145.448)">
              <path d="M220.29,8.123L220.29,13.869L208.98,13.869L208.98,45.719L201.986,45.719L201.986,13.869L190.624,13.869L190.624,8.123L220.29,8.123Z" style="fill:rgb(27,189,147);fill-rule:nonzero;"/>
              <path d="M258.77,26.921C258.77,29.677 258.311,32.229 257.392,34.578C256.473,36.926 255.182,38.959 253.518,40.675C251.854,42.391 249.852,43.73 247.512,44.692C245.172,45.654 242.581,46.134 239.738,46.135C236.895,46.134 234.3,45.654 231.951,44.692C229.602,43.73 227.592,42.391 225.919,40.675C224.246,38.959 222.951,36.926 222.032,34.578C221.113,32.229 220.654,29.677 220.654,26.921C220.654,24.165 221.113,21.612 222.032,19.264C222.951,16.915 224.246,14.883 225.919,13.167C227.592,11.451 229.602,10.112 231.951,9.15C234.3,8.188 236.895,7.707 239.738,7.707C242.581,7.707 245.172,8.192 247.512,9.163C249.852,10.133 251.854,11.472 253.518,13.18C255.182,14.887 256.473,16.915 257.392,19.264C258.311,21.612 258.77,24.165 258.77,26.921ZM251.594,26.921C251.594,24.858 251.321,23.008 250.775,21.37C250.229,19.732 249.445,18.341 248.422,17.197C247.399,16.053 246.156,15.177 244.691,14.571C243.226,13.964 241.575,13.661 239.738,13.661C237.901,13.661 236.245,13.964 234.772,14.571C233.299,15.177 232.046,16.053 231.015,17.197C229.984,18.341 229.191,19.732 228.636,21.37C228.081,23.008 227.804,24.858 227.804,26.921C227.804,28.983 228.081,30.834 228.636,32.472C229.191,34.11 229.984,35.496 231.015,36.632C232.046,37.767 233.299,38.638 234.772,39.245C236.245,39.851 237.901,40.155 239.738,40.155C241.575,40.155 243.226,39.851 244.691,39.245C246.156,38.638 247.399,37.767 248.422,36.632C249.445,35.496 250.229,34.11 250.775,32.472C251.321,30.834 251.594,28.983 251.594,26.921Z" style="fill:rgb(27,189,147);fill-rule:nonzero;"/>
              <path d="M300.344,26.921C300.344,29.677 299.885,32.229 298.966,34.578C298.047,36.926 296.756,38.959 295.092,40.675C293.428,42.391 291.426,43.73 289.086,44.692C286.746,45.654 284.155,46.134 281.312,46.135C278.469,46.134 275.874,45.654 273.525,44.692C271.176,43.73 269.166,42.391 267.493,40.675C265.82,38.959 264.525,36.926 263.606,34.578C262.687,32.229 262.228,29.677 262.228,26.921C262.228,24.165 262.687,21.612 263.606,19.264C264.525,16.915 265.82,14.883 267.493,13.167C269.166,11.451 271.176,10.112 273.525,9.15C275.874,8.188 278.469,7.707 281.312,7.707C284.155,7.707 286.746,8.192 289.086,9.163C291.426,10.133 293.428,11.472 295.092,13.18C296.756,14.887 298.047,16.915 298.966,19.264C299.885,21.612 300.344,24.165 300.344,26.921ZM293.168,26.921C293.168,24.858 292.895,23.008 292.349,21.37C291.803,19.732 291.019,18.341 289.996,17.197C288.973,16.053 287.73,15.177 286.265,14.571C284.8,13.964 283.149,13.661 281.312,13.661C279.475,13.661 277.819,13.964 276.346,14.571C274.873,15.177 273.62,16.053 272.589,17.197C271.558,18.341 270.765,19.732 270.21,21.37C269.655,23.008 269.378,24.858 269.378,26.921C269.378,28.983 269.655,30.834 270.21,32.472C270.765,34.11 271.558,35.496 272.589,36.632C273.62,37.767 274.873,38.638 276.346,39.245C277.819,39.851 279.475,40.155 281.312,40.155C283.149,40.155 284.8,39.851 286.265,39.245C287.73,38.638 288.973,37.767 289.996,36.632C291.019,35.496 291.803,34.11 292.349,32.472C292.895,30.834 293.168,28.983 293.168,26.921Z" style="fill:rgb(27,189,147);fill-rule:nonzero;"/>
              <path d="M312.85,39.947L327.904,39.947L327.904,45.719L305.856,45.719L305.856,8.123L312.85,8.123L312.85,39.947Z" style="fill:rgb(27,189,147);fill-rule:nonzero;"/>
          </g>
          <g transform="matrix(1.31199,0,0,1.31199,-152.099,-199.98)">
              <path d="M158.197,247.195C155.517,246.935 152.818,246.935 150.138,247.195L148.81,253.658C146.241,254.051 143.723,254.725 141.302,255.669L136.921,250.737C134.47,251.851 132.132,253.201 129.941,254.766L132.023,261.027C129.995,262.652 128.152,264.495 126.527,266.523L120.266,264.441C118.701,266.632 117.351,268.97 116.237,271.421L121.169,275.802C120.225,278.223 119.551,280.741 119.158,283.31L112.695,284.638C112.435,287.318 112.435,290.017 112.695,292.697L119.158,294.025C119.551,296.594 120.225,299.112 121.169,301.533L116.237,305.914C117.351,308.365 118.701,310.703 120.266,312.894L126.527,310.812C128.152,312.84 129.995,314.683 132.023,316.308L129.941,322.569C132.132,324.134 134.47,325.484 136.921,326.598L141.302,321.666C143.723,322.61 146.241,323.284 148.81,323.677L150.138,330.14C152.818,330.4 155.517,330.4 158.197,330.14L159.525,323.677C162.094,323.284 164.612,322.61 167.033,321.666L171.414,326.598C173.865,325.484 176.203,324.134 178.394,322.569L176.312,316.308C178.34,314.683 180.183,312.84 181.808,310.812L188.069,312.894C189.634,310.703 190.984,308.365 192.098,305.914L187.166,301.533C188.11,299.112 188.784,296.594 189.177,294.025L195.64,292.697C195.9,290.017 195.9,287.318 195.64,284.638L189.177,283.31C188.784,280.741 188.11,278.223 187.166,275.802L192.098,271.421C190.984,268.97 189.634,266.632 188.069,264.441L181.808,266.523C180.183,264.495 178.34,262.652 176.312,261.027L178.394,254.766C176.203,253.201 173.865,251.851 171.414,250.737L167.033,255.669C164.612,254.725 162.094,254.051 159.525,253.658L158.197,247.195ZM154.168,280.334C158.767,280.334 162.501,284.068 162.501,288.667C162.501,293.267 158.767,297.001 154.168,297.001C149.568,297.001 145.834,293.267 145.834,288.667C145.834,284.068 149.568,280.334 154.168,280.334Z" style="fill:white;stroke:rgb(60,75,100);stroke-width:3.54px;stroke-linecap:round;stroke-miterlimit:1.5;"/>
          </g>
      </g>
  </g>
`,
]
